import axios from 'axios'
import url from '../url'
import {
    getArray, setQuery, getJson, setData,
} from '../resources/commentResource'

export default class commentRepository {
    async index(data) {
        const params = setQuery(data)
        const response = await axios.get(url('indexComments'), { params })
        if (response.status === 200) {
            return getArray(response.data)
        }
    }

    async show(postId) {
        const response = await axios.get(url('showComment', { post: postId }))
        if (response.status === 200) {
            return getJson(response.data.data)
        }
    }

    // async store(data) {
    //     const json = setData(data)
    //     const response = await axios.post(url('storeComment'), json)
    //     if (response.status === 201) {
    //         return getJson(response.data)
    //     }
    // }
    //
    // async update(postId, data) {
    //     const json = setData(data)
    //     const response = await axios.put(
    //         url('updateComment', { post: postId }),
    //         json,
    //     )
    //     if (response.status === 200) {
    //         return response.data.data
    //     }
    // }

    async verify(commentId, data) {
        const response = await axios.put(
            url('verifyComment', { comment: commentId }),
            data,
        )
        if (response.status === 200) {
            return response.data.data
        }
    }

    async delete(commentId) {
        await axios.delete(url('destroyComment', { comment: commentId }))
    }
}
