<template>
  <div>
   <comment-list-filters
       :role-filter.sync="roleFilter"
       :plan-filter.sync="planFilter"
       :status-filter.sync="statusFilter"
       :role-options="roleOptions"
       :plan-options="planOptions"
       :status-options="statusOptions"
       @filter="fetchComment"
   />

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <b-form-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
                @change="changePageComment({page:1 , itemsPerPage: $event})"
            />
            <label>entries</label>
          </b-col>
        </b-row>
      </div>

      <comment-item v-for="(item, key) in comments" :item="item"
        @input="fetchComment"
      ></comment-item>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
                class="text-muted"
            >Showing {{ pagination.pageStart }} to {{ pagination.pageStop }} of
              {{ pagination.itemsLength }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
                v-model="currentPage"
                :total-rows="pagination.itemsLength"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @change="changePageComment({ page: $event , itemsPerPage: perPage})"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormSelect,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from 'bootstrap-vue'
import store from '@/store'
import {ref, onUnmounted, onMounted} from '@vue/composition-api'
import {avatarText} from '@core/utils/filter'
import CommentRepository from '@/abstraction/repository/commentRepository'
import CommentListFilters from './CommentListFilters.vue'
import CommentItem from './CommentItem.vue'
import useCommentList from './useCommentList'
import userStoreModule from '../user/userStoreModule'
// repository
const commentRepository = new CommentRepository()
export default {
  components: {
    CommentListFilters,
    CommentItem,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormSelect,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
  },
  data() {
    return {
      form: {
        description: null,
      },
    }
  },

  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const roleOptions = [
      {
        label: 'Admin',
        value: 'admin',
      },
      {
        label: 'Author',
        value: 'author',
      },
      {
        label: 'Editor',
        value: 'editor',
      },
      {
        label: 'Maintainer',
        value: 'maintainer',
      },
      {
        label: 'Subscriber',
        value: 'subscriber',
      },
    ]

    const planOptions = [
      {
        label: 'Basic',
        value: 'basic',
      },
      {
        label: 'Company',
        value: 'company',
      },
      {
        label: 'Enterprise',
        value: 'enterprise',
      },
      {
        label: 'Team',
        value: 'team',
      },
    ]

    const statusOptions = [
      {
        label: 'Pending',
        value: 'pending',
      },
      {
        label: 'Active',
        value: 'active',
      },
      {
        label: 'Inactive',
        value: 'inactive',
      },
    ]

    const {
      fetchComment,
      tableColumns,
      perPage,
      pagination,
      currentPage,
      totalUsers,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      comments,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useCommentList()

    onMounted(async () => {
      await fetchComment()
    })

    const changePageComment = paginate => {
      fetchComment(paginate)
    }
    return {
      // Sidebar
      fetchComment,
      comments,
      pagination,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      changePageComment,
      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    }
  },
  methods: {

    async deleteComment(commentId) {
      await commentRepository.delete(commentId)
      const index = this.comments.findIndex(x => x.id === commentId)
      this.$delete(this.comments, index)
    },
    shortDescription(value) {
      const words = value.split(' ')
      const wordsSliced = words.slice(0, 9)
      if (words.length > 10) {
        return `${wordsSliced.join(' ')}...`
      }
      return wordsSliced.join(' ')
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

a {
  color: $red;
}
</style>
