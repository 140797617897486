<template>
  <div>
    <div class="comment-item p-2 m-2 mr-3 ml-3">
      <b-row>
        <div class="col-sm-3">
          <span>User: <b>{{ item.user.name }}</b></span>
        </div>
        <div class="col-sm-2">
          <span>Type: <b>{{ item.commentable_type }}</b></span>
        </div>
        <div class="col-sm-3">
          <span>Item: <b>{{ item.commentable.title }}</b></span>
        </div>
        <div class="col-sm-2">
          <span>Created: <b>{{ item.created_at }}</b></span>
        </div>
        <div dir="rtl" class="d-flex col-sm-2">
          <b-button v-if="item.status !== 'rejected'"
                    variant="danger"
                    class="mr-1"
                    @click="changeStatus(item.id , {status: 'rejected'})"
          >
            &#128473;
          </b-button>
          <b-button v-if="item.status !== 'approved'"
                    variant="success"
                    class="mr-1"
                    @click="changeStatus(item.id , {status: 'approved'})"
          >
            <feather-icon icon="CheckIcon"/>
          </b-button>
        </div>
      </b-row>
      <b-row v-if="item.parent" class="comment-reply m-1 p-1">
        <div class="col-sm-3">
          <span>User: <b>{{ item.parent.user.name }}</b></span>
        </div>
        <div class="col-sm-3">
          <span>Created: <b>{{ item.parent.created_at }}</b></span>
        </div>
        <div class="col-sm-12 mt-1">
          <app-collapse>
            <app-collapse-item style="background-color: rgb(237 237 237)" title="">
              <p>{{ item.parent.message }}</p>
            </app-collapse-item>
          </app-collapse>
        </div>
      </b-row>
      <b-row class="mt-1 mb-1">
        <div class="col-sm-12">
          <p>{{ item.message }}</p>
        </div>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormSelect,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from 'bootstrap-vue'
import CommentListFilters from "@/views/apps/comment/CommentListFilters";
import CommentRepository from "@/abstraction/repository/commentRepository";
import AppCollapse from "@core/components/app-collapse/AppCollapse";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem";

const repository = new CommentRepository()
export default {
  name: "CommentItem",
  components: {
    CommentListFilters,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormSelect,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    AppCollapse,
    AppCollapseItem,
  },
  props: {
    item: {default: {}},
  },
  methods: {
    async changeStatus(commentId, data) {
      await repository.verify(commentId, data)
      this.$emit('input', 'changed')
      // await fetchComment()
    },
  }
}
</script>

<style scoped>
.comment-item {
  border: 1px black solid;
  background-color: #e5e5e5;
  color: black;
  border-radius: 0 10px 10px 10px;
}

.comment-reply {
  border: 1px black solid;
  background-color: rgb(237 237 237);
  color: black;
  border-radius: 0 10px 10px 10px;
}
</style>