import {SetPagination, SetQueriesObject} from '@/utils/setQueriesObject'

export const getJson = data => ({
    id: data.id,
    message: data.message,
    status: data.status,
    commentable_type: capitalizeFirstLetter(data.commentable_type),
    user: data.user ? {
        id: data.user.id,
        name: data.user.name,
        thumbnail: data.user.thumbnail,
    } : {
        id: '',
        name: '',
        thumbnail: '',
    },
    parent: data.parent ? {
        id: data.parent.id,
        message: data.parent.message,
        created_at: data.parent.created_at,
        user: data.parent.user ? {
            id: data.user.id,
            name: data.user.name,
            thumbnail: data.user.thumbnail,
        } : {
            id: '',
            name: '',
            thumbnail: '',
        },
    } : null,
    commentable: data.commentable ? {
        id: data.commentable.id,
        title: data.commentable.title ? data.commentable.title : data.commentable.name,
    } : null,
    created_at: data.created_at,
})
export const getArray = ({data, meta}) => {
    const pagination = SetPagination(meta)
    data = data.map(comment => getJson(comment))
    return {data, pagination}
}
export const setData = data => {
    const formData = {
        title: data.title,
        text: data.text,
        file_batch_id: data.file_batch_id,
    }
    return formData
}
export const setQuery = data => SetQueriesObject(data)

export const capitalizeFirstLetter = ([ first, ...rest ], locale = navigator.language) =>
    first.toLocaleUpperCase(locale) + rest.join('')