import {ref, watch, computed} from '@vue/composition-api'
import store from '@/store'
import {title} from '@core/utils/filter'

// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CommentRepository from '@/abstraction/repository/commentRepository'

const repository = new CommentRepository()

export default function useUsersList() {
    // Use toast
    const toast = useToast()

    // const refUserListTable = ref(null)

    // Table Handlers
    const tableColumns = [
        {key: 'title', sortable: true},
        {key: 'user', sortable: true},
        {key: 'text', sortable: true},
        {key: 'status', sortable: true},
        {key: 'created_at', sortable: true},
        {key: 'actions'},
    ]
    const perPage = ref(10)
    const totalUsers = ref(0)
    const currentPage = ref(1)
    const pageStart = ref(1)
    const pagination = ref({})
    const pageStop = perPage
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    const roleFilter = ref(null)
    const planFilter = ref(null)
    const statusFilter = ref(null)
    const comments = ref([])

    const fetchComment = async (paginate = {page: 1, itemsPerPage: perPage.value}, filters = {}) => {
        const commentList = await repository.index({filters, pagination: paginate})
        comments.value = commentList.data
        pagination.value = commentList.pagination
        return comments
    }

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    const resolveUserRoleVariant = role => {
        if (role === 'subscriber') return 'primary'
        if (role === 'author') return 'warning'
        if (role === 'maintainer') return 'success'
        if (role === 'editor') return 'info'
        if (role === 'admin') return 'danger'
        return 'primary'
    }

    const resolveUserRoleIcon = role => {
        if (role === 'subscriber') return 'UserIcon'
        if (role === 'author') return 'SettingsIcon'
        if (role === 'maintainer') return 'DatabaseIcon'
        if (role === 'editor') return 'Edit2Icon'
        if (role === 'admin') return 'ServerIcon'
        return 'UserIcon'
    }

    const resolveUserStatusVariant = status => {
        if (status === 'pending') return 'warning'
        if (status === 'active') return 'success'
        if (status === 'inactive') return 'secondary'
        return 'primary'
    }

    return {
        fetchComment,
        tableColumns,
        perPage,
        pagination,
        pageStart,
        pageStop,
        currentPage,
        totalUsers,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        comments,

        resolveUserRoleVariant,
        resolveUserRoleIcon,
        resolveUserStatusVariant,

        // Extra Filters
        roleFilter,
        planFilter,
        statusFilter,
    }
}
